import { Turbo } from '@hotwired/turbo-rails'
import { Calendar } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import TurboStreamController from './turbo_stream_controller'

export default class extends TurboStreamController {
  static targets = ['calendar']
  static values = {
    date: String,
    organizationId: String,
    settings: Object
  }

  connect () {
    this.initializeCalendar()

    this.appointmentsCalendarChannel = this.application.consumer.subscriptions.create(
      {
        channel: 'AppointmentsCalendarChannel',
        organization_id: this.organizationIdValue
      }, {
        received: (data) => {
          switch (data.action) {
            case 'refresh':
              this.refresh()
              break
          }
        }
      }
    )
  }

  disconnect () {
    this.appointmentsCalendarChannel.unsubscribe()
    this.calendar.destroy()
  }

  initializeCalendar () {
    const _this = this

    this.calendar = new Calendar(this.calendarTarget, {
      schedulerLicenseKey: FULLCALENDAR_LICENSE_KEY,
      headerToolbar: { start: 'prev,next today add new_pet', center: 'title', end: 'timeGridWeek,timeGridDay' },
      titleFormat: { month: 'long', year: 'numeric', day: 'numeric', weekday: 'long' },
      events: '/appointments/calendar.json',
      editable: true,
      navLinks: true,
      nowIndicator: true,
      expandRows: true,
      stickyHeaderDates: true,
      initialDate: this.dateValue,
      initialView: 'timeGridWeek',
      slotMinTime: '07:00:00',
      slotMaxTime: '22:00:00',
      slotDuration: '00:30:00',
      slotLabelInterval: { minutes: '30' },
      slotLabelFormat: { hour: 'numeric', minute: '2-digit', meridiem: false, hour12: false },
      displayEventTime: false,
      allDaySlot: true,
      firstDay: 1,
      longPressDelay: 3000, // 3 seconds for drag
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
      customButtons: {
        add: {
          text: '+ Visit',
          click: () => { _this.customButtonAdd() }
        },
        new_pet: {
          text: '+ Pet',
          click: () => { _this.customButtonNewPet() }
        }
      },
      eventClick: (info) => { _this.eventClick(info) },
      dateClick: (info) => { _this.dateClick(info) },
      eventDrop: (info) => { _this.eventDrop(info) }
    })

    if (this.settingsValue.fit_to_screen === true) {
      this.calendar.setOption('contentHeight', 'auto')
    }

    this.calendar.render()
  }

  customButtonAdd () {
    const url = new URL(`${window.location.origin}/appointments/new/step_one`)
    url.searchParams.append('allow', true)

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  customButtonNewPet () {
    const url = new URL(`${window.location.origin}/pets/new`)

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  dateClick (info) {
    info.jsEvent.preventDefault()

    const url = new URL(`${window.location.origin}/appointments/new/step_one`)
    url.searchParams.append('form[first_event_at]', info.dateStr)
    url.searchParams.append('form[all_day]', info.allDay)

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  eventClick (info) {
    info.jsEvent.preventDefault()

    const url = `/appointments/${info.event.id}/edit/step_one`

    this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
  }

  eventDrop (info) {
    if (info.event.extendedProps.recurring) {
      const url = new URL(`${window.location.origin}/appointments/${info.event.id}/calendar_move/step_one`)

      url.searchParams.append('from', 'calendar')
      url.searchParams.append('form[start_time]', info.event.start)
      url.searchParams.append('form[end_time]', info.event.end)
      url.searchParams.append('form[all_day]', info.event.allDay)

      this.renderTurboStream(url).then(() => document.dispatchEvent(new CustomEvent('modal:show')))
    } else {
      const data = {
        from: 'calendar',
        'form[start_time]': info.event.start,
        'form[end_time]': info.event.end,
        'form[all_day]': info.event.allDay
      }

      fetch(`/appointments/${info.event.id}/calendar_move.json`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
        },
        body: new URLSearchParams(data).toString()
      }).then(response => {
        if (!response.ok) {
          return response.json().then(data => {
            Turbo.renderStreamMessage(data.html)
            document.dispatchEvent(new CustomEvent('modal:show'))
          })
        }
      }).catch(error => { console.error('Error:', error) })
    }
  }

  refresh () {
    this.calendar.refetchEvents()
  }
}
