import { Application } from '@hotwired/stimulus'
import { Autocomplete } from 'stimulus-autocomplete'
import consumer from '../channels/consumer'

const application = Application.start()

application.consumer = consumer

// Configure Stimulus development experience
application.debug = process.env.NODE_ENV !== 'production'
// Register autocomplete
application.register('autocomplete', Autocomplete)

import AppointmentsCalendarController from "./appointments_calendar_controller"
application.register("appointments-calendar", AppointmentsCalendarController)

import AppointmentsCloseModalController from "./appointments_close_modal_controller"
application.register("appointments-close-modal", AppointmentsCloseModalController)

import AppointmentsEditModalController from "./appointments_edit_modal_controller"
application.register("appointments-edit-modal", AppointmentsEditModalController)

import AppointmentsNewModalController from "./appointments_new_modal_controller"
application.register("appointments-new-modal", AppointmentsNewModalController)

import AuthController from "./auth_controller"
application.register("auth", AuthController)

import CalendarRefreshController from "./calendar_refresh_controller"
application.register("calendar-refresh", CalendarRefreshController)

import EventsCalendarController from "./events_calendar_controller"
application.register("events-calendar", EventsCalendarController)

import EventsController from "./events_controller"
application.register("events", EventsController)

import ExaminationsRehabilitationPlansTreatmentsAutocompleteController from "./examinations_rehabilitation_plans_treatments_autocomplete_controller"
application.register("examinations-rehabilitation-plans-treatments-autocomplete", ExaminationsRehabilitationPlansTreatmentsAutocompleteController)

import ExaminationsRehabilitationPlansTreatmentsListController from "./examinations_rehabilitation_plans_treatments_list_controller"
application.register("examinations-rehabilitation-plans-treatments-list", ExaminationsRehabilitationPlansTreatmentsListController)

import FlashController from "./flash_controller"
application.register("flash", FlashController)

import ModalsController from "./modals_controller"
application.register("modals", ModalsController)

import PaymentsExportsController from "./payments_exports_controller"
application.register("payments-exports", PaymentsExportsController)

import PetsFormController from "./pets_form_controller"
application.register("pets-form", PetsFormController)

import PillSelectorController from "./pill_selector_controller"
application.register("pill-selector", PillSelectorController)

import RoomsAutocompleteController from "./rooms_autocomplete_controller"
application.register("rooms-autocomplete", RoomsAutocompleteController)

import RoomsListController from "./rooms_list_controller"
application.register("rooms-list", RoomsListController)

import ScreenLockController from "./screen_lock_controller"
application.register("screen-lock", ScreenLockController)

import ScreenLockHandlerController from "./screen_lock_handler_controller"
application.register("screen-lock-handler", ScreenLockHandlerController)

import ScreenLockTimerController from "./screen_lock_timer_controller"
application.register("screen-lock-timer", ScreenLockTimerController)

import ScreenLockUnlockController from "./screen_lock_unlock_controller"
application.register("screen-lock-unlock", ScreenLockUnlockController)

import ShiftsCalendarController from "./shifts_calendar_controller"
application.register("shifts-calendar", ShiftsCalendarController)

import ToggleVisibilityController from "./toggle_visibility_controller"
application.register("toggle-visibility", ToggleVisibilityController)

import TooltipsController from "./tooltips_controller"
application.register("tooltips", TooltipsController)

import TreatmentsAutocompleteController from "./treatments_autocomplete_controller"
application.register("treatments-autocomplete", TreatmentsAutocompleteController)

import TreatmentsListController from "./treatments_list_controller"
application.register("treatments-list", TreatmentsListController)

import TurboStreamController from "./turbo_stream_controller"
application.register("turbo-stream", TurboStreamController)

import UsersAutocompleteController from "./users_autocomplete_controller"
application.register("users-autocomplete", UsersAutocompleteController)

window.Stimulus = application
